<template>
  <v-container class="mt-12">
    <v-row class="py-12">
 

      <v-col cols="12" lg="6" md="6" sm="12">
        <img
          style="object-fit: cover;"
          width="50%"
          height="400"
          src="../../assets/s2-1.jpg"
          class="px-2 pb-12"
        />
        <img
          style="object-fit: cover;"
          width="50%"
          height="400"
          class="px-2 pt-12"
          src="../../assets/s2-2.png"
        />
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12" class="pt-12 px-12">
        <p
          class="accent--text pt-2 caption"
          style="border-bottom: 1px solid black;
          padding-bottom: 5px;
          width: max-content;
          border-color: #a67d58;"
        >
          {{ $t("home.s2.HOW_WE_ARE") }}
        </p>
        <h2 class="accent--text">{{ $t("home.s2.Serah_al_Belad") }}</h2>
        <h4>{{ $t("home.s2.GENERAL_CONSTRUCTION") }}</h4>
        <p class="pt-12 accent--text caption">
          <b>{{ $t("home.s2.s2_sub") }}</b>
        </p>
        <p class="caption">
          {{ $t("home.s2.s2_sub2") }}
        </p>
        <v-btn color="primary" class="mt-6" outlined to="/about">
          <h5 class="accent--text">{{ $t("readmore") }}</h5></v-btn>
      </v-col>

      <!-- <v-col cols="12" lg="3" md="6">
        <v-lazy
          v-model="isActive"
          :options="{
            threshold: 0.5,
          }"
        >
          <v-fab-transition>
            <custom-card
              :icon="plugin.icon"
              :title="plugin.title"
              :subtitle="plugin.subtitle"
              :text="plugin.text"
              :buttons="plugin.buttons"
            /> </v-fab-transition
        ></v-lazy>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    isActive: false,
  }),
  computed: {},
};
</script>

<style lang="scss" scoped></style>
